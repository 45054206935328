import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["start", "end"]

  onStartChange() {
    console.log("on start change")
    if (this.#isMissingEnd || this.#isEndBeforeStart) {
      this.#endValue = this.#startValue
    }
  }

  onEndChange() {
    console.log("on end change")
    if (this.#isMissingStart || this.#isEndBeforeStart) {
      this.#startValue = this.#endValue
    }
  }

  get #isMissingStart() {
    return this.#startValue.length === 0
  }

  get #isMissingEnd() {
    return this.#endValue.length === 0
  }

  get #isEndBeforeStart() {
    return this.#endValue[0] < this.#startValue[0]
  }

  get #startValue() {
    return this.startTarget._flatpickr.selectedDates
  }

  get #endValue() {
    return this.endTarget._flatpickr.selectedDates
  }

  set #startValue(value) {
    this.startTarget._flatpickr.setDate(value)
  }

  set #endValue(value) {
    this.endTarget._flatpickr.setDate(value)
  }
}
